import HomeBanner from "../../components/HomeBanner";
import Button from "@mui/material/Button";
import { IoIosArrowRoundForward } from "react-icons/io";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
// import Rating from '@mui/material/Rating';
// import { TfiFullscreen } from "react-icons/tfi";
// import { MdOutlineFavoriteBorder } from "react-icons/md";
import ProductItem from "../../components/ProductItem";
import HomeCategory from "../../components/HomeCategory";
import banner3 from "../../assets/images/banner3.png";
import banner4 from "../../assets/images/banner4.png";
import footerBanner from "../../assets/images/footerBanner.png";
import { IoMailOutline } from "react-icons/io5";

const Home = () => {
  return (
    <>
      <HomeBanner />

      <HomeCategory />

      <section className="homeProducts">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="sticky">
                <div className="banner">
                  <img
                    src="https://fullstack-ecommerce.netlify.app/static/media/banner1.957b2952d2e9b8c1f445.jpg"
                    alt="banner"
                    className="cursor w-100"
                  />
                </div>

                <div className="banner mt-4">
                  <img
                    src="https://fullstack-ecommerce.netlify.app/static/media/banner2.23a22290d952eb371fc6.jpg"
                    alt="banner"
                    className="cursor w-100"
                  />
                </div>
              </div>
            </div>

            <div className="col-md-9 productRow">
              <div className="d-flex align-items-center">
                <div className="bestSellerInfo w-75">
                  <h3 className="mb-0 hd">BEST SELLER</h3>
                  <p className="text-light text-small mb-0">
                    Do not miss the current offers until the end of August.
                  </p>
                </div>

                <Button className="viewAllButton ms-auto">
                  View All
                  <IoIosArrowRoundForward />
                </Button>
              </div>

              {/* Product View */}
              <div className="productCardRow w-100 mt-4">
                <Swiper
                  slidesPerView={4}
                  spaceBetween={5}
                  pagination={{ clickable: true }}
                  className="mySwiper"
                  modules={[Navigation]}
                >
                  <SwiperSlide>
                    <ProductItem />
                  </SwiperSlide>
                  <SwiperSlide>
                    <ProductItem />
                  </SwiperSlide>
                  <SwiperSlide>
                    <ProductItem />
                  </SwiperSlide>
                  <SwiperSlide>
                    <ProductItem />
                  </SwiperSlide>
                  <SwiperSlide>
                    <ProductItem />
                  </SwiperSlide>
                  <SwiperSlide>
                    <ProductItem />
                  </SwiperSlide>
                  {/* <SwiperSlide>
                    <div className="item productItem">
                      <div className="imageWrapper">
                        <img src="https://cdn.igp.com/f_auto,q_auto,t_pnopt10prodlp/products/p-nuts-and-dates-dry-cake-400-gms--180163-m.jpg" 
                          alt="card" className="w-100" />
                      </div>
                      <div className="productInfo">
                        <h4>Nuts and Dates Dry Cake (400 Gms)</h4>
                        <span className="text-success d-block">In Stock</span>
                        <Rating className="mt-2 mb-2" size="small" name="simple-controlled" value="3.5" readOnly="true" precision={0.5} />
                        <div className="d-flex">
                          <span className="oldPrice">₹ 600</span>
                          <span className="newPrice text-danger ms-2">₹ 550</span>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="item productItem">
                      <div className="imageWrapper">
                        <img src="https://cdn.igp.com/f_auto,q_auto,t_pnopt10prodlp/products/p-classic-mixed-dry-fruits-loaf-cake-250-gm--265859-m.jpg" 
                          alt="card" className="w-100" />
                      </div>
                      <div className="productInfo">
                        <h4>Classic Mixed Dry Fruits Loaf Cake (250 gm)</h4>
                        <span className="text-success d-block">In Stock</span>
                        <Rating className="mt-2 mb-2" size="small" name="simple-controlled" value="3.5" readOnly="true" precision={0.5} />
                        <div className="d-flex">
                          <span className="oldPrice">₹ 600</span>
                          <span className="newPrice text-danger ms-2">₹ 550</span>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="item productItem">
                      <div className="imageWrapper">
                        <img src="https://cdn.igp.com/f_auto,q_auto,t_pnopt10prodlp/products/p-delicious-truffle-cake-half-kg--145982-m.jpg" 
                          alt="card" className="w-100" />
                      </div>
                      <div className="productInfo">
                        <h4>Delicious Truffle Cake (Half Kg)</h4>
                        <span className="text-success d-block">In Stock</span>
                        <Rating className="mt-2 mb-2" size="small" name="simple-controlled" value="3.5" readOnly="true" precision={0.5} />
                        <div className="d-flex">
                          <span className="oldPrice">₹ 600</span>
                          <span className="newPrice text-danger ms-2">₹ 550</span>
                        </div>
                      </div>                      
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="item productItem">
                      <div className="imageWrapper">
                        <img src="https://cdn.igp.com/f_auto,q_auto,t_pnopt10prodlp/products/p-choco-chip-truffle-cake-300-gm--265855-m.jpg" 
                          alt="card" className="w-100" />
                      </div>
                      <div className="productInfo">
                        <h4>Choco Chip Truffle Cake (300 gm)</h4>
                        <span className="text-success d-block">In Stock</span>
                        <Rating className="mt-2 mb-2" size="small" name="simple-controlled" value="3.5" readOnly="true" precision={0.5} />
                        <div className="d-flex">
                          <span className="oldPrice">₹ 600</span>
                          <span className="newPrice text-danger ms-2">₹ 550</span>
                        </div>
                      </div>                      
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="item productItem">
                      <div className="imageWrapper">
                        <img src="https://cdn.igp.com/f_auto,q_auto,t_pnopt10prodlp/products/p-minion-madness-fondant-cake-1-kg--285312-m.jpg" 
                          alt="card" className="w-100" />
                      </div>
                      <div className="productInfo">
                        <h4>Minion Madness Fondant Cake (1 kg)</h4>
                        <span className="text-success d-block">In Stock</span>
                        <Rating className="mt-2 mb-2" size="small" name="simple-controlled" value="3.5" readOnly="true" precision={0.5} />
                        <div className="d-flex">
                          <span className="oldPrice">₹ 600</span>
                          <span className="newPrice text-danger ms-2">₹ 550</span>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="item productItem">
                      <div className="imageWrapper">
                        <img src="https://cdn.igp.com/f_auto,q_auto,t_pnopt10prodlp/products/p-love-together-cake-half-kg--110571-m.jpg" 
                          alt="card" className="w-100" />
                      </div>
                      <div className="productInfo">
                        <h4>Love Together Cake (Half Kg)</h4>
                        <span className="text-success d-block">In Stock</span>
                        <Rating className="mt-2 mb-2" size="small" name="simple-controlled" value="3.5" readOnly="true" precision={0.5} />
                        <div className="d-flex">
                          <span className="oldPrice">₹ 600</span>
                          <span className="newPrice text-danger ms-2">₹ 550</span>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="item productItem">
                      <div className="imageWrapper">
                        <img src="https://cdn.igp.com/f_auto,q_auto,t_pnopt10prodlp/products/p-girl-power-photo-cake-half-kg--111996-m.jpg" 
                          alt="card" className="w-100" />
                      </div>
                      <div className="productInfo">
                        <h4>Girl Power Photo Cake (Half Kg)</h4>
                        <span className="text-success d-block">In Stock</span>
                        <Rating className="mt-2 mb-2" size="small" name="simple-controlled" value="3.5" readOnly="true" precision={0.5} />
                        <div className="d-flex">
                          <span className="oldPrice">₹ 600</span>
                          <span className="newPrice text-danger ms-2">₹ 550</span>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide> */}
                </Swiper>
              </div>

              <div className="d-flex align-items-center mt-5">
                <div className="bestSellerInfo w-75">
                  <h3 className="mb-0 hd">NEW PRODUCTS</h3>
                  <p className="text-light text-small mb-0">
                    New products with updated stocks.
                  </p>
                </div>
              </div>

              {/* Product View */}
              <div className="productCardRow newProductRow w-100 mt-4 d-flex">
                <ProductItem />
                <ProductItem />
                <ProductItem />
                <ProductItem />
                <ProductItem />
                <ProductItem />
                <ProductItem />
                <ProductItem />
              </div>

              <div className="d-flex mt-4 mb-5 bannerSection">
                <div className="banner">
                  <img src={banner3} alt="banner" className="cursor w-100" />
                </div>{" "}
                <div className="banner">
                  <img src={banner4} alt="banner" className="cursor w-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="newsLetterSection mt-3 mb-3 d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <p className="text-white">$20 discount for your first order</p>
              <h3 className="text-white">Join our newsletter and get...</h3>
              <p className="text-light">
                Join our email subscription now to get updates on <br /> promotions and coupons.
              </p>

              <form>
                <IoMailOutline />
                <input type="text" placeholder="Your Email Address" />
                <Button>
                  Subscribe
                </Button>
              </form>
            </div>
            <div className="col-md-6">
              <img src={footerBanner} alt="banner" />
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
      <br />
    </>
  );
};

export default Home;
