import Button from "@mui/material/Button";
import { FiUser } from "react-icons/fi";
import { IoBagOutline } from "react-icons/io5";

const UserMenu = () => {
  return (
    <>
      <div className="userMenu d-flex align-items-center ms-auto">
        <Button className="userCircle me-3"><FiUser /></Button>
        <div className="ms-auto cartMenu d-flex align-items-center">
            <span className="orderTotal">$3.99</span>
            <div className="cart ms-2">
                <Button className="userCircle"><IoBagOutline /></Button>
                <span className="itemCount d-flex align-items-center justify-content-center">2</span>
            </div>            
        </div>
      </div>
    </>
  );
};

export default UserMenu;
