import Slider from "react-slick";

const HomeBanner = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
  };

  return (
    <>
      <div className="row mt-4">
        <div className="col-md-4">

        </div>
        <div className="col-md-8">
          <div className="homeBannerSection">
            <Slider {...settings}>
              <div className="item">
                <img src='https://bakefit.in/wp-content/uploads/2023/06/Slider04-scaled.jpg' className="w-100" alt="t1" />
              </div>          
              <div className="item">
                <img src='https://bakefit.in/wp-content/uploads/2023/06/Slider05-scaled.jpg' className="w-100" alt="t1" />
              </div>          
              <div className="item">
                <img src='https://bakefit.in/wp-content/uploads/2023/06/Slider02-scaled.jpg' className="w-100" alt="t1" />
              </div>          
              <div className="item">
                <img src='https://bakefit.in/wp-content/uploads/2023/06/Slider03-scaled.jpg' className="w-100" alt="t1" />
              </div>          
              <div className="item">
                <img src='https://bakefit.in/wp-content/uploads/2023/06/Slider01-scaled.jpg' className="w-100" alt="t1" />
              </div>          
            </Slider>
          </div>
        </div>
      </div>

    </>
  );
};

export default HomeBanner;
