import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { IoIosSearch  } from "react-icons/io";
import { MdClose } from "react-icons/md";
import React, { useContext, useEffect, useState } from 'react';
import Slide from '@mui/material/Slide';
import { MyContext } from '../../App';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OutletDropdown = () => {

  const [open, setOpen] = useState(false)
  const [selectedOutletIndex, setSelectedOutletIndex] = useState(null)
  const [filterOutletList, setFilterOutletList] = useState([])

  const context = useContext(MyContext);

  const onSelectOutlet = (index, outletName) => {
    setSelectedOutletIndex(index);
    setOpen(false);
    context.setSelectedOutlet(outletName);
  }

  useEffect(() => {
    context.setSelectedOutlet(context.outletList?.length !== 0 && context.outletList?.filter(item => item.IsMainCounter === true)[0].OutletName);
    setFilterOutletList(context.outletList)
  }, [])  

  const filterOutlet = (e) => {
    const keyword = e.target.value.toLowerCase();

    if(keyword !== "") {
      const list = context.outletList.filter((item) => {
        return item.OutletName.toLowerCase().includes(keyword);
      });
      setFilterOutletList(list);
    } else {
      setFilterOutletList(context.outletList);
    }
  }

  return (
    <>
      <Button className="outletDropdown" onClick={() => setOpen(true)}>
        <div className="info d-flex flex-column">
          <span className="label">Outlet</span>
          <span className="name">
              {
                context.selectedOutlet !== "" 
                  ? (context.selectedOutlet.length > 10 ? context.selectedOutlet?.substr(0, 10) + '...' : context.selectedOutlet)
                  : "Select outlet"
              }          
          </span>
        </div>
      </Button>

      <Dialog open={open} className='outletModel' onClose={() => setOpen(false)} TransitionComponent={Transition}>
        <h4 className='mb-2'>Choose your outlet</h4>
        <p>Select the outlet from where you want to order your product.</p>
        <Button className='closeBtn' onClick={() => setOpen(false)}><MdClose /></Button>

        <div className="headerSearch w-100">
          <input type="text" placeholder="Search for outlet..." onChange={filterOutlet} />
          <Button><IoIosSearch /></Button>
        </div>
        
        <ul className='outletList mt-3 ms-0'>
          {
            filterOutletList?.length !== 0 && filterOutletList?.map((item, index) => {
              return (
                <li key={index}>
                  <Button onClick={() => onSelectOutlet(index, item.OutletName)}
                    className={`${selectedOutletIndex === index ? 'active' : ''}`}>{item.OutletName}</Button>
                </li>
              );
            })
          }
        </ul>
      </Dialog>
    </>
  );
};

export default OutletDropdown;