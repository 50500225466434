import Button from '@mui/material/Button';
import { IoIosMenu, IoIosContact, IoMdHome } from "react-icons/io";
import { FaAngleDown, FaAngleRight  } from "react-icons/fa6";
import { CiGift  } from "react-icons/ci";
import { Link } from 'react-router-dom'
import { MdOutlineBakeryDining, MdOutlineVolunteerActivism } from "react-icons/md";
import { GiFrozenBlock } from "react-icons/gi";
import { LuPackage } from "react-icons/lu";
import { useState } from 'react';

const Navigation = () => {

  const [openSidebarNav, setOpenSidebarNav] = useState(true)

  return (
    <>
      <nav>
          <div className="container">
              <div className="row">
                  <div className="col-sm-2 allCategoryMenu">
                    <div className="categoryWrapper">
                      <Button className="allCategories align-items-center" onClick={() => setOpenSidebarNav(!openSidebarNav)}>
                          <span className='menuIcon me-2'><IoIosMenu /></span>                
                          <span className='text'>ALL CATEGORIES</span>                          
                          <span className='angleIcon ms-2'><FaAngleDown /></span>
                          <div class="description">TOTAL 63 PRODUCTS</div>
                      </Button>

                      <div className={`sidebarNav ${openSidebarNav === true ? 'open' : ''}`}>
                        <ul>
                          <li>
                            <Link to='/'><Button><MdOutlineBakeryDining />&nbsp;Bakery Items <FaAngleRight className='ms-auto' /></Button></Link>
                            <div className="subMenu">
                              <Link to='/'><Button>Biscuits</Button></Link>
                              <Link to='/'><Button>Patties</Button></Link>
                              <Link to='/'><Button>Pizzas</Button></Link>
                              <Link to='/'><Button>Cakes</Button></Link>
                            </div>
                          </li>
                          <li><Link to='/'><Button><GiFrozenBlock />&nbsp;Frozen Items</Button></Link></li>
                          <li><Link to='/'><Button><LuPackage />&nbsp;Packed Snacks</Button></Link></li>
                          <li><Link to='/'><Button><CiGift />&nbsp;Gift Packs</Button></Link></li>
                          <li><Link to='/'><Button><MdOutlineVolunteerActivism />&nbsp;Volunteers</Button></Link></li>
                        </ul>
                      </div>
                    </div>                    
                  </div>

                  <div className="col-sm-10 navigationMenu d-flex align-items-center">
                    <ul className='list list-inline ms-auto'>
                      <li className='list-inline-item'>
                        <Link to='/'><IoMdHome />&nbsp;Home</Link>
                      </li>
                      <li className='list-inline-item'>
                        <Link to='/'><MdOutlineBakeryDining />&nbsp;Bakery Items</Link>
                        <div className="subMenu shadow">
                          <Link to='/'><Button>Biscuits</Button></Link>
                          <Link to='/'><Button>Patties</Button></Link>
                          <Link to='/'><Button>Pizzas</Button></Link>
                          <Link to='/'><Button>Cakes</Button></Link>
                        </div>
                      </li>
                      <li className='list-inline-item'>
                        <Link to='/'><GiFrozenBlock />&nbsp;Frozen Items</Link>
                      </li>
                      <li className='list-inline-item'>
                        <Link to='/'><LuPackage />&nbsp;Packed Snacks</Link>
                      </li>
                      <li className='list-inline-item'>
                        <Link to='/'><CiGift />&nbsp;Gift Packs</Link>
                      </li>
                      <li className='list-inline-item'>
                        <Link to='/'><MdOutlineVolunteerActivism />&nbsp;Volunteers</Link>
                      </li>
                      <li className='list-inline-item'>
                        <Link to='/'><IoIosContact  />&nbsp;About Us</Link>
                      </li>
                    </ul>
                  </div>
              </div>
          </div>
      </nav>        
    </>
  )
}

export default Navigation