import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';

const HomeCategory = () => {

  return (
    <section className="homeCategory mt-3">
        <div className="container">
            <h3 className="mb-3 hd">FEATURED CATEGORIES</h3>
            <Swiper slidesPerView={10} spaceBetween={3} className="mySwiper" navigation={true} slidesPerGroup={1} modules={[Navigation]}>
                <SwiperSlide>
                    <div className="item text-center cursor">
                        <img src="https://nest-frontend-v6.vercel.app/assets/imgs/shop/cat-13.png" alt="cake" />
                        <h5>Cakes</h5>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="item text-center cursor">
                        <img src="https://nest-frontend-v6.vercel.app/assets/imgs/shop/cat-3.png" alt="cake" />
                        <h5>Snacks</h5>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="item text-center cursor">
                        <img src="https://nest-frontend-v6.vercel.app/assets/imgs/shop/cat-9.png" alt="cake" />
                        <h5>Red Apple</h5>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="item text-center cursor">
                        <img src="https://nest-frontend-v6.vercel.app/assets/imgs/shop/cat-9.png" alt="cake" />
                        <h5>Red Apple</h5>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="item text-center cursor">
                        <img src="https://nest-frontend-v6.vercel.app/assets/imgs/shop/cat-9.png" alt="cake" />
                        <h5>Red Apple</h5>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="item text-center cursor">
                        <img src="https://nest-frontend-v6.vercel.app/assets/imgs/shop/cat-9.png" alt="cake" />
                        <h5>Red Apple</h5>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="item text-center cursor">
                        <img src="https://nest-frontend-v6.vercel.app/assets/imgs/shop/cat-9.png" alt="cake" />
                        <h5>Red Apple</h5>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="item text-center cursor">
                        <img src="https://nest-frontend-v6.vercel.app/assets/imgs/shop/cat-9.png" alt="cake" />
                        <h5>Red Apple</h5>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="item text-center cursor">
                        <img src="https://nest-frontend-v6.vercel.app/assets/imgs/shop/cat-9.png" alt="cake" />
                        <h5>Red Apple</h5>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="item text-center cursor">
                        <img src="https://nest-frontend-v6.vercel.app/assets/imgs/shop/cat-9.png" alt="cake" />
                        <h5>Red Apple</h5>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="item text-center cursor">
                        <img src="https://nest-frontend-v6.vercel.app/assets/imgs/shop/cat-9.png" alt="cake" />
                        <h5>Red Apple</h5>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="item text-center cursor">
                        <img src="https://nest-frontend-v6.vercel.app/assets/imgs/shop/cat-9.png" alt="cake" />
                        <h5>Red Apple</h5>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="item text-center cursor">
                        <img src="https://nest-frontend-v6.vercel.app/assets/imgs/shop/cat-9.png" alt="cake" />
                        <h5>Red Apple</h5>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="item text-center cursor">
                        <img src="https://nest-frontend-v6.vercel.app/assets/imgs/shop/cat-9.png" alt="cake" />
                        <h5>Red Apple</h5>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
    </section>
  )
}

export default HomeCategory