import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import Home from './pages/Home';
import Header from './components/Header';
import { createContext, useEffect, useState } from 'react';
import axios from 'axios';
import React from 'react'

const MyContext = createContext()

function App() {

  const [outletList, setOutletList] = useState([]);
  const [selectedOutlet, setSelectedOutlet] = useState('')

  useEffect(() => {
    getOutlets();
  }, []);
  
  const getOutlets = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/outlet/getall`);
    
    var tmpOutletList = [];

    if(response.status === 200) {
      tmpOutletList = response.data.data;
    }

    setOutletList(tmpOutletList);
  }

  const values = {
    outletList,
    setSelectedOutlet,
    selectedOutlet
  };

  return (
    <BrowserRouter>
      <MyContext.Provider value={values}>
        <Header />
        <Routes>
          <Route path="/" exact={true} element={<Home />} />          
        </Routes>
      </MyContext.Provider>
    </BrowserRouter>
  );
}

export default App;

export { MyContext }