import Logo from '../../assets/images/logo.png'
import { Link } from 'react-router-dom'
import OutletDropdown from '../OutletDropdown'
import SearchBar from '../SearchBar'
import UserMenu from '../UserMenu'
import Navigation from '../Navigation'
import { useContext } from 'react'
import { MyContext } from '../../App'

const Header = () => {

  const context = useContext(MyContext);
    
  return (
    <>
        <div className="headerWrapper">
            <div className="top-strip bg-blue">
                <div className="container">
                    <p className="mb-0 mt-0 text-center">
                        Due to the <b>COVID 19</b> epidemic, orders cannot be delivered to home. You have to pick your order from store.
                    </p>
                </div>
            </div>

            <header className="header">
                <div className="container">
                    <div className="row">
                        <div className="logoWrapper d-flex align-item-center col-sm-1">
                            <Link to={'/'}>
                                <img src={Logo} alt='Logo' />
                            </Link>
                        </div>

                        <div className="col-sm-11 d-flex align-item-center contentRight">

                            {
                                context.outletList.length !==0 && <OutletDropdown />
                            }                            

                            {/* Header Search Bar */}
                            <SearchBar />

                            {/* User and Cart */}
                            <UserMenu />
                        </div>
                    </div>
                </div>
            </header>

            <Navigation />
                        
        </div>
    </>
  )
}

export default Header