import Button from '@mui/material/Button';
import { TfiFullscreen } from "react-icons/tfi";
import { IoMdHeartEmpty } from "react-icons/io";
import Rating from '@mui/material/Rating';

const ProductItem = () => {
  return (
    <div className="item productItem">
        <div className="imageWrapper">
            <img src="https://cdn.igp.com/f_auto,q_auto,t_pnopt17prodlp/products/p-classic-red-velvet-cake-half-kg--109230-m.jpg" 
                alt="card" className="w-100" />
            <span class="badge badge-primary">5%</span>
            <div className="actionOverlay">
                <Button><TfiFullscreen /></Button>
                <Button><IoMdHeartEmpty style={{fontSize: '20px'}} /></Button>
            </div>
        </div>

        <div className="productInfo">
            <h4>Classic Red Velvet Cake (Half Kg)</h4>
            <span className="text-success d-block">In Stock</span>
            <Rating className="mt-2 mb-2" size="small" name="simple-controlled" value="3.5" readOnly="true" precision={0.5} />
            <div className="d-flex">
                <span className="oldPrice">₹ 600</span>
                <span className="newPrice text-danger ms-2">₹ 550</span>
            </div>
        </div>
    </div>
  )
}

export default ProductItem